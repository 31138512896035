<template>
  <v-container fluid>
    <div v-if="School && School.bilan">
      <div class="container">
        <div class="d-flex">
          <h3 class="mb-5">Montant total deposé à la banque : {{ functions.formatNumber(School.balance) }} FCFA</h3>
          
        </div>
        <div class="d-flex justify-end">
          <h3 class="mb-5">Etudiant</h3>
        </div>
        <v-row>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.bilan.enumeration.student.totalStudent"
              :description="'Etudiants'"
              :icon="'mdi-account-group'"
              :color="'grey lighten-3'"
              :options="School.bilan.option"
              :optionTypes="['option-student-enumeration']"
            />
          </v-col>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.bilan.enumeration.student.totalMaleStudent"
              :description="'Etudiants'"
              :icon="'mdi-human-male'"
              :color="'grey lighten-3'"
              :options="School.bilan.option"
              :optionTypes="['option-student-enumeration']"
            />
          </v-col>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.bilan.enumeration.student.totalFemaleStudent"
              :description="'Etudiantes'"
              :icon="'mdi-human-female'"
              :color="'grey lighten-3'"
              :options="School.bilan.option"
              :optionTypes="['option-student-enumeration']"
            />
          </v-col>
        </v-row>
      </div>
      <div class="container">
        <div class="d-flex justify-end">
          <h3 class="mb-5">Paiement Annuel</h3>
        </div>
        <v-row>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.schoolTotalAmountDebts + ' F CFA'"
              :description="'Montant Espéré'"
              :icon="'mdi-cash-multiple'"
              :color="'yellow lighten-3'"
              :options="School.bilan.option"
              :optionTypes="['option-student-payment']"
            />
          </v-col>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.schoolTotalPaid + ' F CFA'"
              :description="'Montant Recouvrir'"
              :icon="'mdi-cash-lock-open'"
              :color="'yellow lighten-3'"
              :options="School.bilan.option"
              :optionTypes="['option-student-payment']"
            />
          </v-col>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.schoolTotalLeftDebts + ' F CFA'"
              :description="'Reste à recouvrir'"
              :icon="'mdi-cash-refund'"
              :color="'yellow lighten-3'"
              :options="School.bilan.option"
              :optionTypes="['option-student-payment']"
            />
          </v-col>
        </v-row>
      </div>
      <div class="container">
        <div class="d-flex justify-end">
          <h3 class="mb-5">Frais annexes</h3>
        </div>
        <v-row>
          <v-col
            v-for="(item, i) of School.bilan.annexes"
            :key="i"
            cols="12"
            md="4"
          >
            <bilan-card1
              :title="item.totalDebt + ' F CFA'"
              :description="item.name"
              :icon="'mdi-cash-multiple'"
              :color="'grey lighten-3'"
              :feesBilan="item"
            />
          </v-col>
        </v-row>
      </div>
      <div class="container">
        <div class="d-flex justify-end">
          <h3 class="mb-5">Frais de scolarité</h3>
        </div>
        <!-- {{School.bilan}} -->
        <v-row>
          <v-col cols="12" md="3">
            <bilan-card1
              :title="School.bilan.schoolFees.totalDebt + ' F CFA'"
              :description="'Montant Espéré'"
              :icon="'mdi-cash-multiple'"
              :color="'yellow lighten-3'"
              :feesBilan="School.bilan.schoolFees"
            />
          </v-col>
          <v-col cols="12" md="3">
            <bilan-card1
              :title="School.bilan.schoolFees.totalPayment + ' F CFA'"
              :description="'Montant Recouvrir'"
              :icon="'mdi-cash-multiple'"
              :color="'yellow lighten-3'"
              :feesBilan="School.bilan.schoolFees"
            />
          </v-col>
          <v-col cols="12" md="3">
            <bilan-card1
              :title="School.bilan.schoolFees.totalLeft + ' F CFA'"
              :description="'Reste à recouvrir '"
              :icon="'mdi-cash-multiple'"
              :color="'yellow lighten-3'"
              :feesBilan="School.bilan.schoolFees"
            />
          </v-col>
          <v-col cols="12" md="3">
            <bilan-card1
              :title="School.bilan.schoolFees.totalReduction + ' F CFA'"
              :description="'Réduction'"
              :icon="'mdi-cash-multiple'"
              :color="'yellow lighten-3'"
              :feesBilan="School.bilan.schoolFees"
            />
          </v-col>
        </v-row>
      </div>
      <div class="container d-none">
        <div class="d-flex justify-end">
          <h3 class="mb-5">Frais additionel</h3>
        </div>
        <!-- {{School.bilan}} -->
        <v-row>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.bilan.additionalCoast.totalDebt + ' F CFA'"
              :description="'Montant Espéré'"
              :icon="'mdi-cash-multiple'"
              :color="'grey lighten-3'"
              :feesBilan="School.bilan.additionalCoast"
            />
          </v-col>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.bilan.additionalCoast.totalPayment + ' F CFA'"
              :description="'Montant Recouvrir'"
              :icon="'mdi-cash-multiple'"
              :color="'grey lighten-3'"
              :feesBilan="School.bilan.additionalCoast"
            />
          </v-col>
          <v-col cols="12" md="4">
            <bilan-card1
              :title="School.bilan.additionalCoast.totalLeft + ' F CFA'"
              :description="'Reste à recouvrir '"
              :icon="'mdi-cash-multiple'"
              :color="'grey lighten-3'"
              :feesBilan="School.bilan.additionalCoast"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "../../utils/data";
import { addHall, updateHall, deleteHall } from "../../api/user";
import BilanCard1 from "../../components/universal/bilanCard1.vue";
import { functions } from "@/utils/constant";

// import EditorX from "../../components/universal/EditorX.vue";
// import DeleteDialog from "../../components/universal/DeleteDialog.vue";
export default {
  name: "School",
  components: {
    BilanCard1,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    functions,
    headers: [
      {
        text: "Nom",
        align: "start",
        sortable: false,
        value: "name",
      },

      {
        text: "Description",
        sortable: false,
        value: "description",
      },
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
        sortable: false,
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["School"]),
    fields() {
      return [
        {
          name: "name",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions(["getMySchool"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getMySchool();
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les données")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addHall;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateHall;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteHall;
    },
  },
};
</script>
  
  <style >
</style>
  