<template>
  <div>
    <v-card class="rounded-xl ml-4 pa-3" :color="color" flat>
      <v-card-title>
        <span class="text-h6 font-weight-light">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-list-item-avatar right rounded>
          <v-icon size="40">{{ icon }}</v-icon>
        </v-list-item-avatar>
      </v-card-title>

      <v-card-text class=""> {{ description }} </v-card-text>
      <v-card-actions>
        <v-btn
          @click="readMore"
          outlined
          color="red"
          text
          rounded
          right
          bottom
          class="pa-2"
        >
          Lire plus ...
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- dialog -->
    <v-dialog v-model="dialog" class="transparent py-0 my-0" max-width="70rem">
      <v-alert
        class="white my-0 py-5"
        text
        dense
        color="irgib"
        icon="mdi-clock-fast"
        border="left"
      >
        <div v-if="options">
          <p class="">Options</p>
          <v-divider class="my-4 irgib" style="opacity: 0.22"></v-divider>
          <v-row>
            <v-col v-for="(item, i) of options" :key="i" cols="4">
              <div>
                <p class="text-h6 mb-3">{{ item.name }}</p>
                <div>
                  <!-- option student enumeration -->
                  <ul v-if="optionTypes.includes('option-student-enumeration')">
                    <li>
                      <span class="font-weight-bold">Totale Etudiants : </span>
                      {{ item.totalStudent }}
                    </li>
                    <li>
                      <span class="font-weight-bold"
                        >Totale Etudiants :
                      </span>
                      {{ item.totalMaleStudent }}
                    </li>
                    <li>
                      <span class="font-weight-bold">Totale Etudiantes : </span>
                      {{ item.totalFemaleStudent }}
                    </li>
                  </ul>
                  <!-- option student payment -->
                  <ul v-if="optionTypes.includes('option-student-payment')">
                    <li>
                      <span class="font-weight-bold">Montant Espéré : </span>
                      {{ item.totalAmountStandardDebts }} F CFA
                    </li>
                    <li>
                      <span class="font-weight-bold">Montant Recouvrir : </span>
                      {{ item.totalStandardPaid }} F CFA
                    </li>
                    <li>
                      <span class="font-weight-bold">Reste à recouvrir : </span>
                      {{ item.totalLeftStandardDebts }} F CFA
                    </li>
                  </ul>
                </div>
                <!-- {{item}} -->
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="feesBilan">
          <p class="">Bilan des frais</p>
          <v-divider class="my-4 irgib" style="opacity: 0.22"></v-divider>
          <ul >
            <li>
              <span class="font-weight-bold">Montant Espéré : </span>
              {{ feesBilan.totalDebt }} F CFA
            </li>
            <li>
              <span class="font-weight-bold">Montant Recouvrir : </span>
              {{ feesBilan.totalPayment }} F CFA
            </li>
            <li>
              <span class="font-weight-bold">Reste à recouvrir : </span>
              {{ feesBilan.totalLeft }} F CFA
            </li>
          </ul>
        </div>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "mdi-account-group",
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "grey lighten-3",
    },
    options: {
      type: Array,
      required: false,
    },
    optionTypes: {
        type: Array,
        default: () => [],
    },
    feesBilan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    readMore() {
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>